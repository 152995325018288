import React from "react";
import Layout from "../components/Layout";
import Blog from "../components/Blog";
import { graphql } from "gatsby";
import MyHelmet from "../context/MyHelmet";

export default ({
  data: {
    allWpPost: { nodes }
  }
}) => {
  return (
    <div>
      <MyHelmet
        page="blog"
        language="en"
        image={nodes[0].featuredImage.node.sourceUrl}
      />
      <Layout>
        <Blog posts={nodes} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  {
    allWpPost(
      filter: { language: { code: { eq: EN } } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        date(locale: "")
        id
        uri
        title
        featuredImage {
          node {
            altText
            description
            sourceUrl
          }
        }
        seo {
          metaDesc
          title
        }
      }
    }
  }
`;
